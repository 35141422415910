import styled from "styled-components";

export const ScreenSharingWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  //width: 100%;
  //height: 100vh;
`;
export const LogoSharing = styled.div`
  width: 315px;
  height: 175px;
  position: absolute;
  top: 67px;
  z-index: 2;
  & img {
    width: 100%;
  }
  @media(max-width: 992px){
    position: inherit;
    top: 0;
  }
`;

export const ScreenSharingImage = styled.div`
  position: relative;
  background: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 240px;
  height: 520px;
  border-radius: 26px;
  border: 15px solid #01194A;
  margin-top: 141px;
  background-color: #ffffff;
  @media(max-width: 992px){
    margin-top: 32px;
  }
`;


export const ScreenSharingUserWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 312px;
  height: 86px;
  box-sizing: border-box;
  position: relative;
  margin-top: -43px;
`;
export const LeftDiv = styled.div`
  background-color: #fff;
  transform: skewX(22deg);
  height: 86px;
  width: 156px;
  margin-right: -34px;
  border-top-left-radius: 6px;
`;
export const RightDiv = styled.div`
  background-color: #fff;
  transform: skewX(-22deg);
  height: 86px;
  width: 156px;
  border-top-right-radius: 6px;
`;
export const ScreenSharingIce = styled.div`
  position: absolute;
  width: 252px;
  height: 62px;
  bottom: -43px;
  left: 29px;

  & img {
    width: 100%;
  }
`;


export const UserInfo = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  & .user-info-sher {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #031121;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 4px;

    & img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  & .user-score-sher {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #031121;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    //margin-left: -10px;
    & img {
      width: 24px;
      height: 24px;
    }

    & .score-count {
      color: #031121;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

export const QRWrapper = styled.div`
  position: absolute;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 4px solid #01194A;
  background: linear-gradient(158deg, #1D4D73 3.42%, #05223D 98.82%);
  width: 179px;
  height: 308px;
  z-index: 3;
  top: 280px;
  left: 287px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & h3 {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-top: 32px;
  }
  & .qr-image-wrapper{
    width: 179px;
    height: 152px;
    background: linear-gradient(126deg, #F27361 0%, #BF4A39 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    & img{
      width: 138px;
      height: 138px;
    }
  }
  & p{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 144px;
  }
  @media(max-width: 992px){
    display: none;
  }
`;

export const GooglePlay = styled.div`
  width: 144px;
  height: 42px;
  margin-top: 19px;
  display: none;
  & a{
    width: 100%;
  }
  & img{
    width: 100%;
  }
  @media(max-width: 992px){
    display: block;
  }
`;
export const IOS = styled(GooglePlay)`
  width: 180px;
  height: 52px;
  & a{
    width: 100%;
  }
  & img{
    width: 100%;
  }
`;
