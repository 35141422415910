import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyCdSk9MOx3pvxegdN3QLryOSbeizKTZO18",
    authDomain: "questar-f3f09.firebaseapp.com",
    databaseURL: "https://questar-f3f09-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "questar-f3f09",
    storageBucket: "questar-f3f09.appspot.com",
    messagingSenderId: "24516258531",
    appId: "1:24516258531:web:258537964d44032794b3d6",
    measurementId: "G-34ZN53YVHB"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

if (app && analytics) {
    console.log("Firebase initialized");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
