import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getReport} from "../Api/ApiWorker";
import logoSharing from '../assets/logo-sharing.png';
import ice from '../assets/ice-user-info.png';
import cub from '../assets/icon_award_filled.png';
import qr from '../assets/QR_install.png';
import google from '../assets/google-play-badge.png';
import apple from '../assets/apple.png';
import {
    GooglePlay, IOS,
    LeftDiv,
    LogoSharing, QRWrapper,
    RightDiv, ScreenSharingIce,
    ScreenSharingImage,
    ScreenSharingUserWrapper,
    ScreenSharingWrapper, UserInfo
} from "./StyledSharing";
import {ClipLoader} from "react-spinners";
const ScreenSharing = () => {

    const [report, setReport] = useState({});
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const getReportInfo = async () => {
      if(!params.id) return
      const response = await getReport(params.id);
      if(response){
          setReport(response)
      } else {
          console.log('err')
      }
    }
    useEffect(()=>{
        (async() =>{
            await getReportInfo();
        })();
        // eslint-disable-next-line
    },[])

    const isAndroid = /Android/i.test(
        navigator.userAgent
    );

    useEffect(() => {
        if(report && report.image){
            setLoading(false)
        }
    },[report])

    return (
        <ScreenSharingWrapper>
            {report &&
                <>
                    <LogoSharing><img src={logoSharing} alt={''}/></LogoSharing>
                    {isAndroid ? (
                        <GooglePlay>
                            <a href='https://play.google.com/store/apps/details?id=com.invaders.christmas'>
                                <img src={google} alt={''}/>
                            </a>
                        </GooglePlay>
                    ) : (
                        <IOS>
                           <a href='https://apps.apple.com/us/app/invaders-christmas/id6471141468'>
                               <img src={apple} alt={''}/>
                           </a>
                        </IOS>
                    )}
                    {report.image ? (
                        <ScreenSharingImage image={report.image}></ScreenSharingImage>
                    ) : (
                        <ScreenSharingImage>
                            <div style={{width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'}}>
                            <ClipLoader
                                color={'#BF4A39'}
                                loading={loading}
                                size={64}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /></div>
                        </ScreenSharingImage>
                    )}
                    <ScreenSharingUserWrapper>
                        <LeftDiv></LeftDiv>
                        <RightDiv></RightDiv>
                        <ScreenSharingIce><img src={ice} alt={''}/></ScreenSharingIce>
                        <UserInfo>
                            <div className='user-info-sher'>
                                {report.player && <img src={report.player.avatar} alt={''}/>}
                                {report.player &&  <span className='name'>{report.player.name}</span>}
                            </div>
                            <div className='user-score-sher'>
                                <img src={cub} alt={''}/>
                                <span className='score'>SCORE:</span>
                                {report.player &&  <span className='score-count'>{report.player.score}</span>}
                            </div>
                        </UserInfo>
                    </ScreenSharingUserWrapper>
                </>
            }
            <QRWrapper>
                <h3>Join <br/> Christmas Battle</h3>
                <div className='qr-image-wrapper'>
                    <img src={qr} alt={''}/>
                </div>
                <p>Download Invaders AR Battles </p>
            </QRWrapper>
        </ScreenSharingWrapper>
    );
};

export default ScreenSharing;