import React, {useEffect, useState} from 'react';
import LeaderBoardItem from "./leaderBoardItem";
import ice from '../assets/Ice.png';
import searchImg from '../assets/search.png';
import {IceWrapper, LeaderBoardCollectionWrapper} from "./Styled";
import {ClipLoader} from "react-spinners";
const LeaderBoardCollection = ({sortedLeaderboardData, itemRefs, playerId, noPlayerFound, loading, setLoading}) => {

    const [showNoPlayerModal, setShowNoPlayerModal] = useState(false);
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        let timer;
        if (noPlayerFound) {
            setShowNoPlayerModal(true);
            timer = setTimeout(() => {
                setShowNoPlayerModal(false);
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [noPlayerFound]);


    return (
        <LeaderBoardCollectionWrapper>
            {
                sortedLeaderboardData.length ? (
                    <ul style={{paddingBottom: '9px'}}>
                        {
                            sortedLeaderboardData.map(item => (
                                <div ref={el => itemRefs.current.set(item.playerId, el)}
                                     key={item.playerId}>
                                    <LeaderBoardItem item={item}
                                                     playerId={playerId}/>
                                </div>
                            ))
                        }
                    </ul>
                ) : (
                    <div style={{width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                        <ClipLoader
                            color={'#BF4A39'}
                            loading={loading}
                            size={64}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
             )
            }
            {showNoPlayerModal && <div className='filter'></div>}
            <IceWrapper>
                {showNoPlayerModal ? (
                    <div className='modal'><img src={searchImg} alt={''}/> oops, we can't find a PLAYER with such name
                    </div>
                ) : <div className='white-gradient'></div>}
                <img className='ice' src={ice} alt={''}/>
                <div style={{width: '100%', overflow: 'hidden'}}>
                    <div className='ice-mobile'></div>
                </div>
            </IceWrapper>
        </LeaderBoardCollectionWrapper>
    );
};

export default LeaderBoardCollection;
