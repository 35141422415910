import React from 'react';
import searchImg from '../assets/search.png';
import clearImg from '../assets/close.png';
import {Label, SearchText, SearchWrapper, WhiteLine} from "./Styled";
const Search = ({getLeaderboardForPlayerRequest, clearValue, value, focus, onChange}) => {


    return (
        <SearchWrapper focus={focus}>
            <div className='label-wrapper'>
                <span>#</span>
                <Label>
                    {focus ? (
                        <img className='img-search'
                             src={clearImg}
                             onClick={clearValue}
                             alt={''}/>
                    ) : (
                        <img className='img-search'
                             src={searchImg}
                             alt={''}/>
                    )}
                    <input type='text'
                           placeholder='username'
                           value={value}
                           onChange={(e) => onChange(e)}/>
                    {focus && <WhiteLine></WhiteLine>}
                    {focus && <SearchText onClick={getLeaderboardForPlayerRequest}>SEARCH</SearchText>}
                </Label>
            </div>
            <p className='p-text' >HIGH SCORE</p>
        </SearchWrapper>
    );
};

export default Search;
