import styled from "styled-components";
import bgMain from "../assets/Bg-Leaderboard-desktop.png";
import bgMobile from "../assets/Bg-Leaderboard-mobile.png";
import bgIce from "../assets/ice-mobile.png";
export const AppWrapper = styled.div`
  background: url(${bgMain});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  @media(max-width: 600px){
    background: url(${bgMobile});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 588px;
`;
export const LogoWrapper = styled.div`
  width: 256px;
  height: 120px;
  margin-top: 12px;
  & img {
    width: 100%;
  }
`;

export const ProgressBarWrapper = styled.div`
  width: 520px;
  margin-top: 24px;
    & .text{
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

  @media(max-width: 600px){
    width: calc(100% - 32px);
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  height: 20px;
  border-radius: 12px;
  background: rgba(38, 96, 143, 0.25);
  backdrop-filter: blur(2px);
  margin-top: 8px;
`;
export const Progress = styled.div`
  background: linear-gradient(90deg, #05386C 0%, #2B6794 100%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => props.width}%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 20px;
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-right: 4px;
`;

export const LeaderBoardHeaderWrapper = styled.header`
  width: 100%;
  height: 67px;
  position: relative;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  & .stripe {
    height: 67px;
    position: absolute;
    top: 0;
  }
  & .red {
    width: 6px;
    background-color: #BF4A39;
    left: 20.5%;
    transform: skewX(-22deg);
    z-index: 2;
  }
  & .green {
    background-color: #2B5E57;
    left: calc(20.5% - 18px);
    transform: skewX(-22deg);
    z-index: 2;
    width: 13px;
  }
  & .red-second {
    background-color: #BF4A39;
    left: calc(20.5% - 52px);
    transform: skewX(-22deg);
    z-index: 2;
    width: 28px;
  }
 & .white {
    background-color: #fff;
    left: calc(20.5% - 58px);
    transform: skewX(-22deg);
    z-index: 2;
    width: 6px;
  }
& .green-second {
    background-color: #2B5E57;
    left: 14px;
    transform: skewX(-22deg);
    z-index: 2;
    width: calc(20.5% - 72px);
  }
& .green-last {
    background-color: #2B5E57;
    left: 9px;
    transform: skewX(-15deg);
    z-index: 3;
    width: 20px;
    border-top-left-radius: 6px;
  }
  
  & .main-content {
    background-color: white;
    width: 91%; 
    height: 100%;
    position: relative;
    right: 19px;
    transform: skew(15deg); 
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: 0 0; 
    z-index: 1;
    border-top-right-radius: 6px;
    & h1{
      transform: skew(-15deg);
      color:  #031121;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      margin-right: 8%;
    }
  }
  @media(max-width: 600px){
    width: calc(100% - 32px);
    & .green-last {
      transform: skewX(-7deg);
      left: 4px;
    }
    & .main-content {
      transform: skew(7deg);
      right: 8px;
      justify-content: flex-end;
      & h1{
        transform: skew(-7deg);
        font-size: 20px;
        margin-right: 0;
        padding-right: 30%;
      }
    }
    & .green-second {
      left: 15px;
      transform: skewX(-22deg);
      z-index: 2;
      width: 20px;
      
    }
    & .white {
      left: 35px;
    }
    & .red-second {
      left: 40px;
    }
    & .green {
      left: 74px;
    }
    & .red {
      left: 93px; 
    }
    }
  @media(max-width: 500px) {
    & .main-content {
      & h1{
        padding-right: 25%;
      }
    }
  } 
  @media(max-width: 450px) {
    & .main-content {
      & h1{
        padding-right: 18%;
      }
    }
  }
 @media(max-width: 370px) {
    & .main-content {
      & h1{
        padding-right: 13%;
      }
    }
  }
  @media(max-width: 321px) {
    & .main-content {
      & h1{
        padding-right: 7%;
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 56px;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-top: 3px solid rgba(0, 9, 25, 0.25);
  border-bottom: 3px solid rgba(0, 9, 25, 0.25);
  background:  #2B5E57;
  width: 100%;
  & .label-wrapper{
    display: flex;
  }
  & span{
    width: 36px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: block;
  }
 
  & .p-text{
    color: #FFF;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  @media(max-width: 600px){
    width: calc(100% - 32px);
    margin: 0 16px;
    & .p-text{
      display: ${props => props.focus ? 'none' : 'block'};
      font-size: 14px;
      white-space: nowrap;
    }
    & .label-wrapper{
      width: 100%;
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  width: 320px;
  & .img-search {
    width: 24px;
    height: 24px;
  }
  & input{
    outline: none;
    border: none;
    background-color: #2B5E57;
    width: 100%;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    caret-color: #fff;
    &::placeholder{
      color: #FFF;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  @media(max-width: 600px){
    width: calc(100% - 32px);
    justify-content: flex-start;
    & input{
      font-size: 14px;
      width: calc(100% - 100px);
      &::placeholder{
        font-size: 14px;
      }
    }
  }
`;

export const WhiteLine = styled.div`
  height: 1px;
  width: 320px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  @media(max-width: 600px){
    width: 100%;
  }
`;
export const SearchText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 20;
  @media(max-width: 600px){
    font-size: 14px;
  }
`;

export const LeaderBoardCollectionWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 390px;
  background-color: #fff;
  & ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    height: 390px;
    overflow: auto;
  }
  & .filter{
      width: 100%;
      height: 384px;
      position: absolute;
      background: rgba(255, 255, 255, 0.50);
      top: 0;
      z-index: 5;
  }
  @media(max-width: 600px){
    width: calc(100% - 32px);
    & ul{
      height: 370px;
    }
  }
`;

export const IceWrapper = styled.div`
  position: absolute;
  min-height: 48px;
  bottom: -54.5px;
  left: 0;
  z-index: 5;
  width: 100%;
  & .white-gradient{
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
      width: 100%;
      height: 48px;
      margin-bottom: -14px;
    }
  & .modal{
    box-sizing: border-box;
    display: flex;
    width: 588px;
    height: 64px;
    padding: 16px 32px;
    align-items: center;
    gap: 10px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    background-color: #BF4A39;
    margin-bottom: -14px;
    & img{
      width: 24px;
      height: 24px;
    }
  }
  
  & img.ice{
    width: 101.5%;
    margin-left: -5px;
  }
 & div.ice-mobile{
    display: none;
   background: url(${bgIce});
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   width: 102%;
   height: 100px;
   margin-bottom: 3px;
  }

  @media(max-width: 600px){
    bottom: -21%;
    & img.ice{
      display: none;
    }
    & div.ice-mobile{
      display: block;
      margin-left: -8px;
    }
    & .modal{
      font-size: 12px;
      width: 100%;
    }
  }
`;
export const UserInfoWrapper = styled.li`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid rgba(3, 17, 33, 0.05);
  background: var(--White, #FFF);
  & img{
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  & .user-info{
    display: flex;
    align-items: center;
    gap: 12px;
    color: #031121;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    & span{
      padding-right: 17px;
      color:  #031121;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  & .color{
    color: #FFF;
    & span{
      color: #FFF;
    }
  }
  & .score-info {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #031121;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  @media(max-width: 600px){
    padding: 8px 12px;
    & .user-info{
      font-size: 14px;
      gap: 6px;
      & span{
        padding-right: 10px;
      }
    }
    & img{
      width: 24px;
      height: 24px;
    }
    & .score-info {
      font-size: 14px;
    }
  }
  @media(max-width: 350px){
    & .user-info{
      & span{
        max-width: 120px;
      }
    }
  }
`;

export const CupImg = styled.img`
  width: 24px;
  height: 24px;
`;
