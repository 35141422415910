import React, {useEffect, useState} from 'react';
import {getLeaderboardHeader} from "../Api/ApiWorker";
import {Progress, ProgressBarWrapper, ProgressWrapper} from "./Styled";

const ProgressBar = () => {

    const [progress, setProgress] = useState({});
    const [progressPlayers, setProgressPlayers] = useState(0);


    const getProgress = async () => {
      const response = await getLeaderboardHeader();
        setProgress(response);
    }

    useEffect(() => {
        getProgress();
    }, []);

    useEffect(() => {
        if (Object.keys(progress).length){
            const progressPercentage = Math.round((progress.totalProgress / progress.maxProgress) * 100);
            setProgressPlayers(progressPercentage);
        }
    }, [progress]);

    return (
        <ProgressBarWrapper>
            <div className='text'>
                <span>COMMUNITY PROGRESS</span>
                <span>{Object.keys(progress).length ? progress.maxProgress : ''} POINTS</span>
            </div>
            <ProgressWrapper>
                <Progress width={progressPlayers}>{ progressPlayers ? `${progressPlayers}%` : ''}</Progress>
            </ProgressWrapper>
        </ProgressBarWrapper>
    );
};

export default ProgressBar;