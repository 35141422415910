import React from 'react';
import {CupImg, UserInfoWrapper} from "./Styled";
import cup from '../assets/icon_award_filled.png';
const LeaderBoardItem = ({item, playerId}) => {

    const userInfo = playerId === item.playerId ? 'user-info color' : 'user-info';
    const scoreInfo = playerId === item.playerId ? 'score-info color' : 'score-info';

    return (

        <UserInfoWrapper  style={playerId === item.playerId ? { backgroundColor: '#BF4A39'} : null}>
            <div className={userInfo}>
                <span>{item.rank}</span>
                <img src={item.playerAvatar} alt={''}/>
                <span>{item.playerName}</span>
            </div>
            <div className={scoreInfo}>
                <span>{item.score}</span>
                <CupImg src={cup} alt={''}/>
            </div>
        </UserInfoWrapper>
    );

};

export default LeaderBoardItem;