import React from 'react';

import {LeaderBoardHeaderWrapper} from "./Styled";

const LeaderBoardHeader = () => {
    return (
        <LeaderBoardHeaderWrapper>
            <div className="stripe green"></div>
            <div className="stripe red"></div>
            <div className="stripe red-second"></div>
            <div className="stripe white"></div>
            <div className="stripe green-second"></div>
            <div className="stripe green-last"></div>
            <div className="main-content">
                <h1>LEADERBOARD</h1>
            </div>
        </LeaderBoardHeaderWrapper>
);
};


export default LeaderBoardHeader;