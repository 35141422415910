import {getFunctions, httpsCallable} from "firebase/functions";

export const getLeaderboardForPlayer = async (playerId, desiredResultsCount) => {
    return await getLeaderboard("GLOBAL", playerId, desiredResultsCount);
}

export const getGlobalLeaderboard = async (desiredResultsCount) => {
    const result = {
        records: [
            {
                leaderboardId: "",
                playerId: "",
                score: 0,
                rank: 0,
                playerName: "",
                playerAvatar: "",
            }
        ]
    };

    const leaderboard = await getLeaderboard("GLOBAL", null, desiredResultsCount);
    if (leaderboard) {
        result.records = leaderboard.records;
    }

    return result;
}


const getLeaderboard = async (leaderboardType, playerId, desiredResultsCount) => {
    const result = {
        records: [
            {
                leaderboardId: "",
                playerId: "",
                score: 0,
                rank: 0,
                playerName: "",
                playerAvatar: "",
            }
        ]
    };

    const getLeaderboardFunction = httpsCallable(getFunctions(), 'getLeaderboard');
    const data = (await getLeaderboardFunction({
        leaderboardType: leaderboardType,
        playerId: playerId,
        desiredResultsCount: desiredResultsCount
    })).data;

    if (data && data.success && data.result) {
        result.records = data.result;
    }

    return result;
};

export const getLeaderboardTotalProgress = async () => {
    let result = 0;
    const getLeaderboardTotalProgressFunction = httpsCallable(getFunctions(), 'getLeaderboardTotalProgress');
    const data = (await getLeaderboardTotalProgressFunction()).data;
    if (data && data.success && data.result) {
        result = data.result;
    }
    return result;
}
export const getLeaderboardHeader = async () => {
    let result =
    {
        maxProgress: 0,
        totalProgress: 0,
    }
    const getLeaderboardMaxProgressFunction = httpsCallable(getFunctions(), 'getLeaderboardHeader');
    const data = (await getLeaderboardMaxProgressFunction()).data;
    if (data && data.success && data.result) {
        result = data.result;
    }
    return result;
}


export const getPlayerByName = async (name) => {
    const result = {
        name: "",
        score: 0,
        id: "",
        geo: "",
        avatar: "",
    }
    const getPlayerByNameFunction = httpsCallable(getFunctions(), 'getPlayerByName');
    const data = (await getPlayerByNameFunction({
        name: name
    })).data;

    if (data && data.success && data.result) {
        result.name = data.result.name;
        result.score = data.result.score;
        result.id = data.result.id;
        result.geo = data.result.geo;
        result.avatar = data.result.avatar;
    }

    return result;
};

export const getReport = async (reportId) => {

    const result = {
        id: "",
        score: 0,
        image: "",
        player: {
            id: "",
            name: "",
            avatar: "",
            geo: "",
            score: 0
        }
    };
    const getReportFunction = httpsCallable(getFunctions(), 'getReport');
    const data = (await getReportFunction({
        reportId: reportId
    })).data;

    if (data && data.success && data.result) {
        result.id = data.result.id;
        result.score = data.result.score;
        result.image = data.result.image;
        result.player.id = data.result.player.id;
        result.player.name = data.result.player.name;
        result.player.avatar = data.result.player.avatar;
        result.player.geo = data.result.player.geo;
        result.player.score = data.result.player.score;
    }

    return result;
};
