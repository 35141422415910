import {BrowserRouter, Route, Routes} from "react-router-dom";

import './App.css';
import {AppWrapper} from "./components/Styled";
import Main from "./components/Main";
import ScreenSharing from "./components/ScreenSharing";
import Installs from "./components/Installs";

function App() {

    return (

    <BrowserRouter>
        <AppWrapper>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/install" element={<Installs/>}/>
                <Route path="/publicReports/:id" element={<ScreenSharing/>} />
            </Routes>
        </AppWrapper>

    </BrowserRouter>

    );
}

export default App;
