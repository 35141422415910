import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const Installs = () => {
    const navigate = useNavigate()
    const isAndroid = /Android/i.test(
        navigator.userAgent
    );
    const isIOS = /iPhone|iPad|iPod/i.test(
        navigator.userAgent
    );
    useEffect(()=>{
        if(isAndroid){
            window.location.replace('https://play.google.com/store/apps/details?id=com.invaders.christmas');
        } else if(isIOS) {
            window.location.replace('https://apps.apple.com/us/app/invaders-christmas/id6471141468');
        } else {
          navigate('/')
        }
        // eslint-disable-next-line
    },[]);
    return (
        <div>

        </div>
    );
};

export default Installs;