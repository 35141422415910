import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import ProgressBar from "./ProgressBar";
import LeaderBoardHeader from "./LeaderBoardHeader";
import Search from "./Search";
import LeaderBoardCollection from "./leaderBoardCollection";
import {getGlobalLeaderboard, getLeaderboardForPlayer, getPlayerByName} from "../Api/ApiWorker";
import {DESIRED_RESULTS_COUNT} from "./consts";
import isEqual from "lodash/isEqual";
import logo from '../assets/logo.png';
import {LogoWrapper, MainWrapper} from './Styled';
const Main = () => {

    const [value, setValue] = useState('');
    const [focus, setFocus] = useState(false);
    const [leaderboardData, setLeaderboardData] = useState({records: []});
    const [playerId, setPlayerId] = useState("");
    const [noPlayerFound, setNoPlayerFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const itemRefs = useRef(new Map());

    useEffect(() => {
        if (leaderboardData && leaderboardData.records.length && playerId && itemRefs.current.has(playerId)) {
            const element = itemRefs.current.get(playerId);
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            element.style.backgroundColor = 'red'; // Выделение цветом
        }
    }, [playerId,leaderboardData]);

    const onChange = (e) => {

        setValue(e.target.value);
        if(e.target.value !== ''){
            setFocus(true);
        }
    }
    const getLeaderboardForPlayerRequest = async () => {
        setNoPlayerFound(false)
        setLeaderboardData({records: []});

        const playerNames = value;
        setValue('');
        setFocus(false);
        const player = await getPlayerByName(playerNames);
        if (player && player.id) {
            setLoading(true);
            setPlayerId(player.id);
            const leaderboard = await getLeaderboardForPlayer(player.id, DESIRED_RESULTS_COUNT);
            if (leaderboard) {
                setLeaderboardData(leaderboard);
            }
        }else{
            //TODO: Handle error
            setPlayerId('');
            setNoPlayerFound(true);
            const leaderboard = await getGlobalLeaderboard(DESIRED_RESULTS_COUNT);
            setLeaderboardData(leaderboard);
        }

    }

    const getGlobalLeaderboardRequest = useCallback(async () => {
        const leaderboard = await getGlobalLeaderboard(DESIRED_RESULTS_COUNT);
        if (leaderboard && !isEqual(leaderboard.records, leaderboardData.records)) {
            setLeaderboardData(leaderboard);
        }
    }, [leaderboardData]);

    useEffect(() => {
        (async() =>{
            await getGlobalLeaderboardRequest();
        })();
        // eslint-disable-next-line
    }, []);

    const sortedLeaderboardData = useMemo(() => {
        return [...leaderboardData.records].sort((a, b) => a.rank - b.rank);
    }, [leaderboardData]);

    const clearValue = () => {
        setValue('');
        setFocus(false);
        setPlayerId('');
        // setNoPlayerFound(false);
    }
    return (
        <MainWrapper>
            <LogoWrapper><img src={logo} alt={''}/></LogoWrapper>
            <ProgressBar/>
            <LeaderBoardHeader/>
            <Search getLeaderboardForPlayerRequest={getLeaderboardForPlayerRequest}
                    focus={focus}
                    value={value}
                    onChange={onChange}
                    clearValue={clearValue}/>
            <LeaderBoardCollection sortedLeaderboardData={sortedLeaderboardData}
                                   noPlayerFound={noPlayerFound}
                                   itemRefs={itemRefs}
                                   playerId={playerId}
                                   loading={loading}
                                   setLoading={setLoading}/>
        </MainWrapper>
    );
};
export default Main;
